import React, {Fragment, useReducer} from 'react'

import _ from 'underscore'

import LoginActions from '../../../../actions/LoginActions.js'
import reducer from '../../../../helpers/reducer.js'

import {Alert, Divider, IconButton, H3, H6, P, Info, Button, Row, Column, MetricInput} from '../../../../components/UI/index.js'
import {Toggle, Input, Select} from '../../../../components/UI/index.js'

const initialState = {
    loading: false,
    verified: false,
    error: '',
    shippingmethodsLoading: false
}

export default function WooCommerceSettings({user, onChange, reseller}) {
    const [{loading, verified, error, shippingmethodsLoading}, dispatch] = useReducer(reducer, initialState)

    function onChangeChecked(key, event) {
        user.settings.webshops.woocommerce[key] = event.target.checked

        onChange(user)
    }

    function onChangeValue(key, event) {
        user.settings.webshops.woocommerce[key] = event.target.value

        onChange(user)
    }

    function onChangeValues(key, event) {
        user.settings.webshops.woocommerce[key] = event.target.values

        onChange(user)
    }

    function onVerifyConnection() {
        dispatch({loading: true, verified: false, error: ''})

        onChange(user, true)

        setTimeout(() => { // wait for saving
            LoginActions.verifyWebshop('woocommerce', (err) => {
                if (err) {
                    dispatch({loading: false, error: `Verificatie niet succesvol, controleer de ingevulde gegevens. Foutmelding: ${err}`})
                } else {
                    dispatch({loading: false, verified: true})
                }
            })
        }, 1000)
    }

    function onClickGetShippingMethods() {
        dispatch({shippingmethodsLoading: true, error: ''})

        LoginActions.getShippingMethods(user, (err, shippingMethods) => {
            if (!err) {
                shippingMethods = shippingMethods.woocommerce || []

                const oldShippingMethods = JSON.parse(JSON.stringify(user.settings.webshops.woocommerce.shippingMethods || []))
                user.settings.webshops.woocommerce.shippingMethods = []

                shippingMethods.map((shippingMethod) => {
                    const oldShippingmethod = _.findWhere(oldShippingMethods, {id: shippingMethod.id})

                    shippingMethod.disabled = oldShippingmethod ? oldShippingmethod.disabled : false
                    shippingMethod.options = oldShippingmethod ? oldShippingmethod.options : []

                    shippingMethod.shippingClasses.map((shippingClass) => {
                        shippingClass.disabled = oldShippingmethod ? _.findWhere(oldShippingmethod.shippingClasses, {id: shippingClass.id})?.disabled || false : false
                        shippingClass.options = oldShippingmethod ? _.findWhere(oldShippingmethod.shippingClasses, {id: shippingClass.id})?.options || [] : []

                        return shippingClass
                    })

                    user.settings.webshops.woocommerce.shippingMethods.push(shippingMethod)
                })

                onChange(user)
            }

            dispatch({shippingmethodsLoading: false, error: err})
        })
    }

    function onChangeShippingMethodDisabled(index) {
        user.settings.webshops.woocommerce.shippingMethods[index].disabled = !user.settings.webshops.woocommerce.shippingMethods[index].disabled

        onChange(user)
    }

    function onChangeShippingClassDisabled(shippingMethodIndex, shippingClassIndex) {
        if (user.settings.webshops.woocommerce.shippingMethods[shippingMethodIndex].shippingClasses?.[shippingClassIndex]) {
            user.settings.webshops.woocommerce.shippingMethods[shippingMethodIndex].shippingClasses[shippingClassIndex].disabled = !user.settings.webshops.woocommerce.shippingMethods[shippingMethodIndex].shippingClasses[shippingClassIndex].disabled

            onChange(user)
        }
    }

    function onChangeShippingClass(shippingMethodIndex, shippingClassIndex, event) {
        if (user.settings.webshops.woocommerce.shippingMethods[shippingMethodIndex].shippingClasses?.[shippingClassIndex]) {
            user.settings.webshops.woocommerce.shippingMethods[shippingMethodIndex].shippingClasses[shippingClassIndex].options = event.target.values

            onChange(user)
        }
    }

    function onChangeCheckedParcel(key, event) {
        user.settings.parcels[key] = event.target.checked

        onChange(user)
    }

    function onChangeValueParcel(key, event) {
        user.settings.parcels[key] = event.target.value

        onChange(user)
    }

    let enabledOptions = (_.keys(reseller.settings.parcels.products) || []).filter((product) => {
        return _.some(_.keys(reseller.settings.parcels.products[product]), (carrier) => {
            return reseller.settings.parcels.products[product][carrier]
        })
    }).sort()

    enabledOptions = enabledOptions.map((option) => {
        return {value: option, title: option}
    })

    return (
        <div>
            <H3>WooCommerce</H3>

            <H6>Instellingen</H6>
            <Divider/>

            <Toggle
                label='Actief'
                checked={user.settings.webshops.woocommerce.active}
                onChange={(event) => onChangeChecked('active', event)}
            />

            {user.settings.webshops.woocommerce.active &&
                <>
                    <Input
                        label='Webshop URL'
                        value={user.settings.webshops.woocommerce.url}
                        onChange={(event) => onChangeValue('url', event)}
                    />

                    <Input
                        label='Consumer key'
                        info='Deze kun je aanmaken in je WooCommerce omgeving.'
                        value={user.settings.webshops.woocommerce.apiKey}
                        onChange={(event) => onChangeValue('apiKey', event)}
                    />

                    <Input
                        label='Consumer secret'
                        info='Deze kun je aanmaken in je WooCommerce omgeving.'
                        value={user.settings.webshops.woocommerce.apiSecret}
                        onChange={(event) => onChangeValue('apiSecret', event)}
                    />

                    <Input
                        type='number'
                        min={0}
                        max={90}
                        label='Aantal dagen terugkijken voor ophalen bestellingen'
                        placeholder={7}
                        value={user.settings.webshops.woocommerce.nrOfDays}
                        onChange={(event) => onChangeValue('nrOfDays', event)}
                    />

                    <Select
                        label='Update order status in WooCommerce'
                        info=''
                        value={user.settings.webshops.woocommerce.updateStatus}
                        onChange={(event) => onChangeValue('updateStatus', event)}
                    >
                        <option value=''>Nooit</option>
                        <option value='confirm'>Na bevestigen</option>
                        <option value='afterSent'>Na verzenden</option>
                    </Select>

                    <Select
                        label='Op te halen statussen uit WooCommerce'
                        options={[
                            {value: 'pending', title: 'Pending'},
                            {value: 'on-hold', title: 'On-hold'},
                            {value: 'processing', title: 'Processing'},
                            {value: 'completed', title: 'Completed'}
                        ]}
                        allowCustomValues
                        values={user.settings.webshops.woocommerce.statuses || []}
                        onChange={(event) => onChangeValues('statuses', event)}
                    />

                    <Toggle
                        label='Gewicht in gram'
                        info='WooCommerce gebruikt standaard kilogram als gewichtseenheid. Een plugin kan dit aanpassen naar gram, zet in dat geval deze optie aan.'
                        checked={user.settings.webshops.woocommerce.weightInGrams}
                        onChange={(event) => onChangeChecked('weightInGrams', event)}
                    />

                    <br/>
                    <Input
                        label='Straat veld'
                        info='Als gebruik wordt gemaakt van een plugin in WooCommerce die de naam van het straat veld aanpast kan deze hier ingevuld worden.'
                        value={user.settings.webshops.woocommerce.streetField}
                        onChange={(event) => onChangeValue('streetField', event)}
                    />

                    <Input
                        label='Huisnummer veld'
                        info='Als gebruik wordt gemaakt van een plugin in WooCommerce die de naam van het huisnummer veld aanpast kan deze hier ingevuld worden.'
                        value={user.settings.webshops.woocommerce.nrField}
                        onChange={(event) => onChangeValue('nrField', event)}
                    />

                    <Input
                        label='Huisnummer toevoeging veld'
                        info='Als gebruik wordt gemaakt van een plugin in WooCommerce die de naam van het huisnummertoevoeging veld aanpast kan deze hier ingevuld worden.'
                        value={user.settings.webshops.woocommerce.additionField}
                        onChange={(event) => onChangeValue('additionField', event)}
                    />

                    <br/>
                    <br/>
                    <H6>Verzendmethodes</H6>
                    <Divider/>

                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 12}}>
                        <P style={{display: 'flex', flex: 1}}>Verzendmethodes ophalen uit WooCommerce
                            <Info text='Klik op de knop om verzendmethodes uit WooCommerce op te halen. Selecteer vervolgens voor elke verzendmethode de nodige verzendopties.'/>
                        </P>

                        <IconButton loading={shippingmethodsLoading} onClick={onClickGetShippingMethods}>
                            <i className='mdi mdi-refresh'/>
                        </IconButton>
                    </div>

                    {(user.settings.webshops.woocommerce.shippingMethods || []).map((shippingmethod, index) => {
                        return (
                            <Fragment key={`shippingmethod${index}`}>
                                <div>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Toggle
                                            checked={!shippingmethod.disabled}
                                            onChange={() => onChangeShippingMethodDisabled(index)}
                                        />

                                        <P style={{marginLeft: 20, marginBottom: 12, flex: 1, fontWeight: 700}}>{shippingmethod.name}</P>

                                        <div style={{flex: 1}}/>
                                    </div>
                                    {!shippingmethod.disabled &&
                                        <div>
                                            {shippingmethod.shippingClasses?.map((shippingClass, shippingClassIndex) => (
                                                <div key={`shippingClass${shippingClassIndex}`} style={{display: 'flex', alignItems: 'center'}}>
                                                    <Toggle
                                                        style={{marginLeft: 30}}
                                                        checked={!shippingClass.disabled}
                                                        onChange={() => onChangeShippingClassDisabled(index, shippingClassIndex)}
                                                    />

                                                    <P style={{marginBottom: 12, flex: 1}}>{shippingClass.name}</P>

                                                    <Select
                                                        noSort
                                                        label='Verzendopties'
                                                        values={shippingClass.options || []}
                                                        options={enabledOptions}
                                                        onChange={(event) => onChangeShippingClass(index, shippingClassIndex, event)}
                                                        disabled={shippingClass.disabled}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <Divider/>
                            </Fragment>
                        )
                    })}

                    <Toggle
                        label='Automatisch brievenbuspakket'
                        info='Zendingen die aan de ingestelde voorwaarden voldoen worden automatisch aangemeld als brievenbuspakket.'
                        checked={!!user.settings.parcels.autoMailboxParcel}
                        onChange={(event) => onChangeCheckedParcel('autoMailboxParcel', event)}
                    />

                    {user.settings.parcels.autoMailboxParcel &&
                    <>
                        <Row>
                            <Column>
                                <Toggle
                                    label='Maximaal gewicht'
                                    checked={!!user.settings.parcels.useMailboxParcelWeight}
                                    onChange={(event) => onChangeCheckedParcel('useMailboxParcelWeight', event)}
                                />
                            </Column>

                            <Column>
                                <MetricInput
                                    label='Gewicht'
                                    value={user.settings.parcels.mailboxParcelWeight}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelWeight', event)}
                                    placeholder={2000}
                                    max={2000}
                                    append='g'
                                    disabled={!user.settings.parcels.useMailboxParcelWeight}
                                />
                            </Column>
                        </Row>

                        <Row>
                            <Column>
                                <Toggle
                                    label='Maximale waarde'
                                    checked={!!user.settings.parcels.useMailboxParcelValue}
                                    onChange={(event) => onChangeCheckedParcel('useMailboxParcelValue', event)}
                                />
                            </Column>

                            <Column>
                                <MetricInput
                                    label='Waarde'
                                    value={user.settings.parcels.mailboxParcelValue}
                                    onChange={(event) => onChangeValueParcel('mailboxParcelValue', event)}
                                    divisionFactor={100}
                                    prepend='€'
                                    disabled={!user.settings.parcels.useMailboxParcelValue}
                                />
                            </Column>
                        </Row>
                    </>
                    }

                    <Button
                        variant='text'
                        style={{marginBottom: 12, marginLeft: 0}}
                        loading={loading}
                        onClick={onVerifyConnection}
                    >
                        Verifieer
                    </Button>

                    {error && (
                        <Alert onClose={() => dispatch({error: ''})} variant='error'>{error}</Alert>
                    )}

                    {!error && verified && (
                        <Alert onClose={() => dispatch({verified: false})}>Verificatie succesvol.</Alert>
                    )}
                </>
            }
        </div>
    )
}
