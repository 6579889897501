// @ts-check

import React, {useState, useEffect, useRef} from 'react'
import {Alert, Button, H4, H6, IconButton, Input, Modal, Row, Select} from '../UI/index.js'
import VehicleNotAvailable from './VehicleNotAvailable.js'
import useVehicleStore from '../../stores/VehicleStore.js'

/**
 * @typedef {object} EditVehicleModalProps
 * @property {Vehicle} vehicle
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {VehicleType[]} [vehicleTypes]
 * @property {(vehicle: Vehicle) => void} onChange
 * @property {() => void} onArchive
 * @property {string[]} [errors]
 */

/**
 * @component
 *
 * @param {EditVehicleModalProps} props
 * @returns {JSX.Element}
 */
export default function EditVehicleModal({vehicle, open, onClose, vehicleTypes, onChange, onArchive, errors}) {
    const vehicles = useVehicleStore((state) => state.vehicles)
    const [vehicleLocal, setVehicleLocal] = useState(structuredClone(vehicle))
    const [duplicateNameError, setDuplicateNameError] = useState('')
    const nameRef = useRef(null)
    const typeIdRef = useRef(null)

    const originalVehicleType = 'typeId' in vehicle && vehicleTypes?.length > 0 && vehicleTypes.find((type) => type._id === vehicle.typeId)

    const activeVehicleType = vehicleTypes?.length > 0 && vehicleTypes.find((type) => type._id === vehicleLocal.typeId)

    const onSave = () => {
        const requiredFields = [
            {
                value: vehicleLocal.name,
                ref: nameRef
            },
            {
                value: vehicleLocal.typeId,
                ref: typeIdRef
            }
        ]

        let fieldsAreValid = true

        requiredFields.forEach((field) => {
            if (!field.value) {
                if (field.ref) {
                    field.ref.current?.setInvalid()
                }

                if (field.action) {
                    field.action()
                }

                fieldsAreValid = false
            }
        })

        const vehicleWithSameNameExists = vehicles.some((vehicle) => vehicle.name === vehicleLocal.name && vehicle._id !== vehicleLocal._id)

        if (vehicleWithSameNameExists) {
            setDuplicateNameError('Er bestaat al een voertuig met deze naam.')
            return
        }

        if (fieldsAreValid) {
            onChange(vehicleLocal)
        }
    }

    useEffect(() => {
        setVehicleLocal(structuredClone(vehicle))
    }, [vehicle])

    useEffect(() => {
        if (!open) {
            setDuplicateNameError('')
            setVehicleLocal(structuredClone(vehicle))
        } else {
            console.log(vehicle)
        }
    }, [open])

    const inputStyle = {
        marginLeft: 0,
        marginRight: 0
    }

    return (
        <Modal show={open} onClose={onClose} style={{maxWidth: 600}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 24}}>
                <H4>Voertuig</H4>

                <IconButton icon='mdi mdi-close' onClick={onClose}/>
            </div>

            {vehicleTypes?.length === 0 ?
                <Alert variant='warning' style={{marginTop: 12}}>
                    Voertuigsoorten zijn niet beschikbaar. Voeg eerst een voertuigsoort toe.
                </Alert> :
                <>
                    <Row>
                        <Input
                            style={inputStyle}
                            label='Naam'
                            value={vehicleLocal.name}
                            onChange={(event) => {
                                setVehicleLocal({...vehicleLocal, name: event.target.value})
                                setDuplicateNameError('')
                            }}
                            ref={nameRef}
                        />
                    </Row>

                    {duplicateNameError && <Alert variant='error'>{duplicateNameError}</Alert>}

                    <Row style={{gap: 12}}>
                        <Select
                            style={inputStyle}
                            label='Voertuigsoort'
                            value={vehicleLocal.typeId}
                            onChange={(event) => setVehicleLocal({...vehicleLocal, typeId: event.target.value})}
                            ref={typeIdRef}

                        >
                            {vehicleTypes.filter((v) => !v.isArchived || v._id === vehicleLocal.typeId).map((vehicleType) => (
                                <option disabled={vehicleType.isArchived} key={vehicleType._id} value={vehicleType._id}>{vehicleType.name}</option>
                            ))}
                        </Select>
                    </Row>

                    <Row>
                        <Input
                            label='Opmerking voor koerier'
                            value={vehicleLocal.comment}
                            placeholder={activeVehicleType?.comment}
                            onChange={(event) => setVehicleLocal({...vehicleLocal, comment: event.target.value})}
                            style={inputStyle}
                        />
                    </Row>

                    <Row>
                        <Input
                            label='Notities'
                            value={vehicleLocal.notes}
                            placeholder={activeVehicleType?.notes}
                            onChange={(event) => setVehicleLocal({...vehicleLocal, notes: event.target.value})}
                            style={inputStyle}
                        />
                    </Row>


                    <H6 style={{marginTop: 24}}>Voertuig niet beschikbaar</H6>

                    {vehicleLocal?.notAvailableDateTimeRanges?.map((range, index) => (
                        <VehicleNotAvailable
                            key={`${range.start}-${range.end}-${range.reason}-${index}`}
                            range={range}
                            onChange={(newRange) => {
                                const ranges = vehicleLocal.notAvailableDateTimeRanges

                                ranges[index] = newRange

                                setVehicleLocal({...vehicleLocal, notAvailableDateTimeRanges: ranges})
                            }}
                            onRemove={() => {
                                const ranges = [...vehicleLocal.notAvailableDateTimeRanges]

                                ranges.splice(index, 1)

                                console.log(ranges)

                                setVehicleLocal({...vehicleLocal, notAvailableDateTimeRanges: ranges})
                            }}
                        />
                    ))}

                    <Button
                        variant='outline-white'
                        icon='mdi mdi-plus'
                        label='Reservering toevoegen'
                        onClick={() => setVehicleLocal({
                            ...vehicleLocal,
                            notAvailableDateTimeRanges: [
                                ...(vehicleLocal.notAvailableDateTimeRanges || []),
                                {
                                    start: '',
                                    end: '',
                                    reason: ''
                                }
                            ]
                        })}
                        style={{marginLeft: 0}}
                    />


                    {errors?.length > 0 && errors.map((error, index) => (
                        <Alert key={index} variant='error'>
                            {error}
                        </Alert>
                    ))
                    }

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {vehicleLocal._id && !originalVehicleType?.isArchived ?
                            <Button onClick={onArchive} variant='error'>
                                {vehicleLocal.isArchived ? 'Dearchiveren' : 'Archiveren'}
                            </Button> :
                            <Button onClick={onClose} variant='error'>Annuleren</Button>
                        }

                        <Button
                            style={{marginLeft: 6}}
                            onClick={onSave}
                        >
                            Opslaan
                        </Button>
                    </div>
                </>
            }


        </Modal>
    )
}
